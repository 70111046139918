import { Box, createTheme, FormControl, FormControlLabel, Grid, LinearProgress, ThemeProvider } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridEventListener,
  ptBR,
} from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import { authAtom } from '../../../../../states/usuarios/AuthState';
import { IResultadoRealizacaoCompetencia } from '../../../../../commons/types/crm';
import { useCompetenciaActions } from '../../../../../states/competencias/competencia.actions';
import { usePerfilCompetenciasActions } from '../../../../../states/perfilCompetencias/perfilCompetencia.actions';
import { ButtonStato } from '../../../../../commons/styleds/buttons';
import MultiSelect from '../../../../../components/Generics/MultiSelect';
import { IdDescricao } from '../../../../../commons/types/base';
import { TextFieldStato } from '../../../../../commons/styleds/textFields';

export interface ICompetencia {
  id: number;
  descricao?: string;
  ativo?: boolean
}

interface Props {
  novoUsuario: boolean;
}

const empty: IResultadoRealizacaoCompetencia = {
  id: 0,
  problemaDesafio: '',
  acao: '',
  sintese: '',
  resultado: '',
  idCompetencias: [],
  tbCompetencias: [],
  ativo: true,
};

const PerfilRealizacoes: React.FC<Props> = (props: Props) => {
  const { novoUsuario } = props;

  const methods = useFormContext();
  const competenciasActions = useCompetenciaActions();
  const ppcCompetenciasActions = usePerfilCompetenciasActions();

  const auth = useRecoilValue(authAtom);

  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(true);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [perfilRealizacoes, setResultadoRealizacaoCompetencias] = useState<IResultadoRealizacaoCompetencia[]>([]);
  const [perfilRealizacoesTemp, setResultadoRealizacaoCompetenciaTemp] = useState<IResultadoRealizacaoCompetencia>();
  const [selectedResultadoRealizacaoCompetencias, setSelectedResultadoRealizacaoCompetencias] = useState<
    IResultadoRealizacaoCompetencia[] | undefined
  >(undefined);

  const id = methods.watch("id");

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    atualizarFormulario(params.row);
  };

  const [competencias, setCompetencias] = useState<ICompetencia[]>([]);
  const [competencia, setCompetencia] = useState<ICompetencia>();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 60,
    },
    {
      field: 'problemaDesafio',
      headerName: 'Problema - Desafio',
      width: 200,
    },
    {
      field: 'acao',
      headerName: 'Ação',
      width: 160,
    },
    {
      field: 'resultado',
      headerName: 'Resultados',
      width: 160,
    },
    {
      field: 'sintese',
      headerName: 'Síntese',
      width: 160,
    },
    {
      field: 'tbCompetencias',
      headerName: 'Competências',
      width: 250,
      valueGetter: (params: GridCellParams) => {
        const competencias = params.row.tbCompetencias as ICompetencia[];
        const descricoes = competencias.map((comp) => comp.descricao);
        const mostrar = descricoes.join(' ; ');
        return mostrar;
      }
    }
  ];

  const atualizarFormulario = (result: any) => {
    if (result.tbCompetencias != undefined)
      result.idCompetencias = result.tbCompetencias?.map((c: any) => c.id);
    setResultadoRealizacaoCompetenciaTemp(result);
    setIdAtualizar(result.id);
    setLoadingForm(true);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  useEffect(() => {

    competenciasActions
      .list()
      .then((resp: ICompetencia[]) => setCompetencias(resp))
      .catch((err: any) => toast.warn(err));

    ppcCompetenciasActions
      .list(false, `?IdContatoPerfilPlanejamento=${id}`)
      .then((resp: IResultadoRealizacaoCompetencia[]) => {
        setResultadoRealizacaoCompetencias(resp);
      })
      .catch((err: any) => toast.warn(err));

    setLoading(false);
  }, []);

  const [open, setOpen] = useState(false);

  return (
    <>
      {!loadingForm ? (

        <Grid item xs={12} md={12} sm={12}>
          <FormControl required fullWidth variant='outlined'>
            <FormInput
              onChange={(e) => {
                setResultadoRealizacaoCompetenciaTemp({
                  ...perfilRealizacoesTemp,
                  problemaDesafio: e.target.value
                });
              }}
              value={perfilRealizacoesTemp?.problemaDesafio}
              label='Problema Desafio'
              multiline
              maxRows={4}
              disabled
              inputProps={{style: {fontWeight: "700"}}}
              InputLabelProps={{style: {fontWeight: "700"}}}
            />
          </FormControl>

          <FormControl required fullWidth variant='outlined'>
            <FormInput
              onChange={(e) => {
                setResultadoRealizacaoCompetenciaTemp({
                  ...perfilRealizacoesTemp,
                  acao: e.target.value
                });
              }}
              value={perfilRealizacoesTemp?.acao}
              label='Ação'
              multiline
              maxRows={4}
              disabled
              inputProps={{style: {fontWeight: "700"}}}
              InputLabelProps={{style: {fontWeight: "700"}}}
            />
          </FormControl>

          <FormControl required fullWidth variant='outlined'>
            <FormInput
              onChange={(e) => {
                setResultadoRealizacaoCompetenciaTemp({
                  ...perfilRealizacoesTemp,
                  resultado: e.target.value
                });
              }}
              value={perfilRealizacoesTemp?.resultado}
              label='Resultados'
              multiline
              maxRows={4}
              disabled
              inputProps={{style: {fontWeight: "700"}}}
              InputLabelProps={{style: {fontWeight: "700"}}}
            />
          </FormControl>

          <FormControl required fullWidth variant='outlined'>
            <FormInput
              onChange={(e) => {
                setResultadoRealizacaoCompetenciaTemp({
                  ...perfilRealizacoesTemp,
                  sintese: e.target.value
                });
              }}
              value={perfilRealizacoesTemp?.sintese}
              label='Síntese'
              multiline
              maxRows={4}
              disabled
              inputProps={{style: {fontWeight: "700"}}}
              InputLabelProps={{style: {fontWeight: "700"}}}
            />
          </FormControl>

          <FormControl required fullWidth variant='outlined' sx={{ 'marginTop': '20px' }}>
            {competencias &&
              <MultiSelect
                opcoes={competencias as IdDescricao[]}
                setValor={(e: any) => {
                }}
                valor={perfilRealizacoesTemp?.idCompetencias ?? []}
                label="Competências"
              />}
          </FormControl>

          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              paddingTop: '10px',
              paddingBottom: '20px',
            }}
          >
            {idAtualizar ? (
              <ButtonStato
                color='error'
                style={{ marginLeft: '25px' }}
                onClick={() => atualizarFormulario(empty)}
                variant='contained'
              >
                Limpar
              </ButtonStato>
            ) : (
              <></>
            )}
          </div >


        </Grid >
      ) :
        <Box sx={{ width: '100%', paddingTop: "5px" }}>
          {loadingSave &&
            <LinearProgress />
          }
        </Box>}

      <Grid item md={0.5}></Grid>

      <Grid container xs={12} md={12} sm={12}>
        <Box
          sx={{
            height: '55vh',
            width: '100%',
          }}
        >
          <ThemeProvider theme={createTheme({}, ptBR)}>
            <DataGrid
              getRowId={(row) => `${row?.id}_${Math.floor(Math.random() * 20)}`}
              rows={perfilRealizacoes ?? []}
              onRowClick={handleRowClick}
              columns={columns}
              loading={loading}
            />
          </ThemeProvider>
        </Box>
      </Grid>
    </>
  );
};

const BasePage = styled.div`
  padding: 15px;
`;

const FormStato = styled.form`
`;

export const FormInput = styled(TextFieldStato)`
  margin-top: 20px !important;
`;

const FormControlLabelStato = styled(FormControlLabel)`
  margin-top: 20px !important;
`;

export default PerfilRealizacoes;
