import { Typography } from '@mui/material';
import RelatorioTimeSheet from './TimeSheet';

const Configuracoes = () => {
  return (
    <div style={{ width: '100%', paddingRight: '50px' }}>
      <RelatorioTimeSheet />
    </div>
  );
};

export default Configuracoes;
